import { useQuery } from '@tanstack/react-query'
import createFetch from '../fetchApi'
import { accountUrl } from '../api-url'
import accountKeys from './accountKeys'

const fetchApi = createFetch(accountUrl)

async function getAccountById({ queryKey }) {
  const id = queryKey.slice(-1)[0]
  const response = await fetchApi(`/account/${id}`)
  return response.response
}

export default function useAccountById(accountId, suspense = true) {
  return useQuery({
    queryKey: accountKeys.detail(accountId),
    queryFn: getAccountById,
    suspense: suspense,
    enabled: !!accountId,
  })
}
