import userPool from 'UserPool'

export async function getSession() {
  return new Promise((resolve, reject) => {
    const user = userPool.getCurrentUser()

    if (!user) {
      resolve(null)
    }

    user.getSession((err, session) => {
      if (err) {
        console.error(err)
        const error = new Error('Error getting user Session')
        reject(error)
      }
      resolve(session)
    })
  })
}

export async function getJwtToken() {
  const session = await getSession()
  const jwtToken = session?.getAccessToken()?.getJwtToken()
  return jwtToken
}
