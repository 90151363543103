import { useContext, createContext, useState, useEffect } from 'react'

import { useAuth } from 'context/auth-context'

import { getAccountById } from 'api/api-acct-mgmt'

const AccountContext = createContext()

const AccountProvider = (props) => {
  const { auth } = useAuth()

  const [account, setAccount] = useState(null)
  const [isLoading, setIsLoading] = useState(null)

  const updateAccount = async (id) => {
    const accountCopy = { ...account }
    accountCopy.id = id
    try {
      const accountInfo = await getAccountById({
        queryKey: ['account', accountCopy.id]
      })
      accountCopy.accountInfo = accountInfo
      localStorage.setItem('accountId', accountCopy.id)
      setAccount(accountCopy)
    } catch (e) {
      console.error(e, 'account id not found')
    }
  }

  useEffect(() => {
    const doIt = async () => {
      if (auth) {
        setIsLoading(true)

        // If we've got an account in localStorage, use it.
        let accountId = localStorage.getItem('accountId')

        // Perhaps this should be restricted to only non-admins? Admins _might_ have bogus account numbers and things juyst crashy crashy...
        if (!accountId) {
          // Otherwise, see if the user has accounts assigned. (This should ALWAYS be the case...)
          accountId = auth['custom:account_ids']?.split(';')[0]
        }
        if (accountId) {
          await updateAccount(accountId)
        }
        setIsLoading(false)
      }
    }
    doIt() // What do we do when this fails?
  }, [auth, account?.id])

  return (
    <AccountContext.Provider
      value={{
        account,
        updateAccount,
        isLoading
      }}
    >
      {props.children}
    </AccountContext.Provider>
  )
}

const useAccount = () => {
  const context = useContext(AccountContext)
  if (context === undefined) {
    throw new Error('useAccount must be used inside AccountProvider')
  }
  return context
}

export { AccountProvider, AccountContext, useAccount }
