export const gtvUrl = `${process.env.REACT_APP_API_DOMAIN}/gtv/billing-account`

export const accountUrl = `${process.env.REACT_APP_API_DOMAIN}/account-management`

export const listingProcessorUrl = `${process.env.REACT_APP_API_DOMAIN}/listing-processor`

export const indexerUrl = `${process.env.REACT_APP_API_DOMAIN}/viking-indexer`

export const accountListUrl = `${process.env.REACT_APP_API_DOMAIN}/api/rest`

export const typeAheadUrl = `${process.env.REACT_APP_API_DOMAIN}/type-ahead`
