import { Redirect, Route } from 'react-router-dom'

import { useAuth } from 'context/auth-context'

function OpenRoute({ component: Component, ...restOfProps }) {
  const { auth } = useAuth()

  const redirect = auth?.isAdmin ? '/account-home' : '/campaigns'

  return <Route {...restOfProps} render={(props) => (!auth ? <Component {...props} /> : <Redirect to={redirect} />)} />
}

export default OpenRoute
