import React, { Suspense } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'

import { useAuth } from 'context/auth-context'

import OpenRoute from 'components/auth/OpenRoute'
import Header from 'components/sections/Header'
import Footer from 'components/sections/Footer'
import ErrorFallback from 'components/sections/ErrorFalback'
import LoadingSpinner from 'components/atoms/LoadingSpinner'

const ProtectedRoute = React.lazy(() => import('components/auth/ProtectedRoute'))

const Account = React.lazy(() => import('components/sections/Account'))
const AccountEdit = React.lazy(() => import('components/sections/AccountEdit'))
const AccountHome = React.lazy(() => import('components/sections/AccountHomeSection'))
const Budgets = React.lazy(() => import('components/sections/Budget'))
const Campaigns = React.lazy(() => import('components/sections/Campaigns'))
const CampaignDetails = React.lazy(() => import('components/sections/CampaignDetail'))
const CampaignListingDetails = React.lazy(() => import('components/sections/CampaignListingDetails'))
const CreateOrAddListing = React.lazy(() => import('components/sections/CreateOrAddListing'))
const Invoices = React.lazy(() => import('components/sections/Invoices'))
const ListingForm = React.lazy(() => import('components/sections/ListingForm'))
const Listings = React.lazy(() => import('components/sections/Listings'))
const Payments = React.lazy(() => import('components/sections/Payments'))

const ChangePassword = React.lazy(() => import('components/sections/ChangePassword'))
const CreateNewPassword = React.lazy(() => import('components/sections/CreateNewPassword'))
const ForgotPassword = React.lazy(() => import('components/sections/ForgotPassword'))
const Login = React.lazy(() => import('components/sections/Login'))
const ResetPassword = React.lazy(() => import('components/sections/ResetPassword'))
const ConfirmRegisterContent = React.lazy(() => import('components/sections/ConfirmRegisterContent'))

const LoadingView = () => (
  <div className="page-loading-wrap">
    <LoadingSpinner />
  </div>
)

function App() {
  const { auth, isLoading } = useAuth()
  const location = useLocation()

  if (!auth && isLoading) {
    return <LoadingView />
  }

  return (
    <>
      <Header />
      <ErrorBoundary FallbackComponent={ErrorFallback} key={location.pathname}>
        <Suspense
          fallback={
            <main className="main-container">
              <LoadingView />
            </main>
          }
        >
          <Switch>
            <Route path="/reset-password" component={ResetPassword} exact />
            <Route path="/create-new-password" component={CreateNewPassword} exact />
            <Route path="/confirm" component={ConfirmRegisterContent} exact />
            <Route path="/forgot-password" component={ForgotPassword} exact />

            <OpenRoute path="/login" component={Login} exact />

            <ProtectedRoute path="/account-home" exact component={AccountHome} />
            <ProtectedRoute path="/change-password" exact component={ChangePassword} />
            <ProtectedRoute path="/campaigns" exact component={Campaigns} />
            <ProtectedRoute path="/campaigns/:id" exact component={CampaignDetails} />
            <ProtectedRoute path="/listings/:listingId/edit" exact component={ListingForm} />
            <ProtectedRoute path="/account" exact component={Account} />
            <ProtectedRoute path="/budget" exact component={Budgets} />
            <ProtectedRoute path="/listings" exact component={Listings} />
            <ProtectedRoute path="/listings/add-to-campaign/:campaignId" exact component={Listings} />
            <ProtectedRoute path="/campaign/:campaignId/listing/:campaignListingId" exact component={CampaignListingDetails} />
            <ProtectedRoute path="/create-or-add" exact component={CreateOrAddListing} />
            <ProtectedRoute path={['/my-account/edit-details', '/my-account/edit-details/:accountId']} exact component={AccountEdit} />
            <ProtectedRoute path="/invoices" exact component={Invoices} />
            <ProtectedRoute path="/payments" exact component={Payments} />
            <ProtectedRoute path="/" exact component={Campaigns} />

            <Redirect to={{ ...location, pathname: '/campaigns' }} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
      <Footer />
    </>
  )
}

export default App
