import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import cn from 'classnames'

import { useAccount } from 'context/account-context'
import { useAuth } from 'context/auth-context'

import useViewPort from 'hooks/useViewPort'

import AccountName from './AccountName'
import FlexSpacer from 'components/atoms/FlexSpacer'
import Logo from 'components/atoms/Logo'

import { ReactComponent as SwitchAccountIcon } from 'svgs/switchAccount.svg'

import './index.scss'

function Header(props) {
  const { auth, logout } = useAuth()
  const { account } = useAccount()

  const { width } = useViewPort()

  const [state, setState] = React.useState({
    isOpen: false
  })

  const closeMenu = () => setState({ isOpen: false })

  const history = useHistory()

  const handleLogout = () => {
    logout().then(() => {
      history.push('/login')
    })
  }

  return (
    <header
      className={cn(props.theme || '', {
        visible: props.showHeader,
        animate: props.animate
      })}
    >
      <div className={cn('container main', { isOpen: state.isOpen })}>
        <div className="main-logo-container">
          <Logo />
        </div>
        <FlexSpacer />
        <div className="account-change-container">
          {/* Show only to admin condition to be added when Login/User API are in sync */}
          {auth?.isAdmin ? (
            <NavLink to="/account-home" onClick={closeMenu}>
              <SwitchAccountIcon />
            </NavLink>
          ) : null}
        </div>
        {account && width > 1030 && <AccountName />}
        {auth && (
          <div className="actions-container">
            <NavLink to="/campaigns" onClick={closeMenu}>
              Campaigns
            </NavLink>
            <NavLink to="/listings" onClick={closeMenu}>
              Listings
            </NavLink>
            <NavLink to="/budget" onClick={closeMenu}>
              Budget
            </NavLink>
            <div className="drop-trigger">
              <label>Billing</label>
              <div className="drop-container">
                <NavLink to="/invoices" onClick={closeMenu}>
                  Invoices
                </NavLink>
                <NavLink to="/payments" onClick={closeMenu}>
                  Payments
                </NavLink>
              </div>
            </div>
            <div className="drop-trigger">
              <label>Account</label>
              <div className="drop-container">
                <NavLink to="/account" onClick={closeMenu}>
                  Settings
                </NavLink>
                <span
                  onClick={() => {
                    handleLogout()
                    closeMenu()
                  }}
                >
                  Sign Out
                </span>
              </div>
            </div>
          </div>
        )}
        <div className={cn('flyout-trigger', { isOpen: state.isOpen })} onClick={() => setState({ isOpen: state.isOpen ? false : true })}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </header>
  )
}

export default Header
