import { useAccount } from 'context/account-context'

import useAccountById from 'api/accounts/useAccountById'

function AccountName() {
  const { account } = useAccount()
  const { data: accountInfo } = useAccountById(account.id, false)
  const name = accountInfo?.displayName || ''
  return <div className="company-name">{name}</div>
}

export default AccountName
