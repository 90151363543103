import createFetch from './fetchApi'
import snakeToCamel from 'utils/snakeToCamel'
import { format } from 'date-fns'

const ACT_BASE_PATH = '/account-management'
const ACT_URL_DOMAIN = process.env.REACT_APP_API_DOMAIN + ACT_BASE_PATH

const fetchApi = createFetch(ACT_URL_DOMAIN)

export async function createCampaign(campaign) {
  const response = await fetchApi('/ad-campaigns', {
    method: 'POST',
    body: JSON.stringify(campaign)
  })
  return response.response.adCampaign
}

export async function updateCampaign(campaign) {
  const response = await fetchApi(`/ad-campaigns/${campaign.adCampaign.adCampaignId}`, {
    method: 'Put',
    body: JSON.stringify(campaign)
  })
  return response.response?.adCampaign
}
const blankEvents = {
  count: 0,
  avgPrice: 0,
  totalPrice: 0,
  totalCost: 0
}

function addSupplyEvents(objects) {
  return objects.map((obj) => {
    if (!obj?.supplyEventDetails?.Click) {
      return { ...obj, totalClicks: 0, billableClicks: 0, billableCost: 0 }
    }
    const objTypes = ['LowQuality', 'Surplus', 'Billable']
    // const typeFields = ['count', 'totalPrice', 'avgPrice', 'totalCost']
    const completeSupplyEvents = {}
    objTypes.forEach((t) => {
      if (!obj.supplyEventDetails.Click[t]) {
        completeSupplyEvents[t] = blankEvents
      } else {
        completeSupplyEvents[t] = obj.supplyEventDetails.Click[t]
      }
    })

    const totalClicks = Object.keys(completeSupplyEvents).reduce((acc, x) => {
      acc += completeSupplyEvents[x].count
      return acc
    }, 0)
    const billableClicks = completeSupplyEvents.Billable.count
    const billableCost = completeSupplyEvents.Billable.totalPrice
    return { ...obj, totalClicks, billableClicks, billableCost }
  })
}

export async function getCampaigns(context) {
  const accountId = context.queryKey[1]
  const page = context.queryKey[2]
  // const startDate = transformDate(context.queryKey[3])
  const startDate = context.queryKey[3] ? format(context.queryKey[3], 'dd/LLL/yyy') : ''
  const endDate = context.queryKey[4] ? format(context.queryKey[4], 'dd/LLL/yyy') : ''
  const name = context.queryKey[5]
  // const endDate = transformDate(context.queryKey[4])
  const response = await fetchApi(
    `/ad-campaigns/aggregate?accountId=${accountId}&page=${page}&size=20&reportStartDate=${startDate}&reportEndDate=${endDate}&name=${name}&v=2`
  )

  response.response = addSupplyEvents(response.response)
  return response
}

export async function getCampaignById(context) {
  const id = context.queryKey[1]
  const response = await fetchApi(`/ad-campaigns/${id}`)
  const res2 = await fetchApi(`/ad-campaigns/${id}/recurring-charges`)
  const campaign = {
    ...response.response.adCampaign,
    configuredBudget: response.response.configuredBudget,
    budgetSummary: response.response.budgetSummary,
    recurringCharges: res2.response
  }

  return campaign
}

export async function getAccountListings(context) {
  const id = context.queryKey[1]
  const page = context.queryKey[2]
  const size = 20
  const response = await fetchApi(`/account/${id}/imported-listing?page=${page}&size=${size}`)

  const listingIds = response.response.map((item) => item.importedListingId)
  if (!listingIds.length) {
    return {
      pageable: { totalPages: 0 },
      response: []
    }
  }

  return response
}

const graphQLEndpoint = 'https://api-dev.elocal.com/viking-distribution/graphql'
export async function getCampaignListings(context) {
  const id = context.queryKey[1]
  const page = context.queryKey[2]
  const startDate = context.queryKey[3] ? format(context.queryKey[3], 'dd/LLL/yyy') : ''
  const endDate = context.queryKey[4] ? format(context.queryKey[4], 'dd/LLL/yyy') : ''
  const name = context.queryKey[5]
  const size = 20
  const response = await fetchApi(
    `/ad-campaign-listing/aggregate?page=${page}&size=${size}&adCampaignId=${id}&reportStartDate=${startDate}&reportEndDate=${endDate}&v=2`
  )

  const listingIds = response.response.map((item) => item.importedListingId)
  if (!listingIds.length) {
    return {
      pageable: { totalPages: 0 },
      response: []
    }
  }
  let listings = addSupplyEvents(response.response)
  return {
    pageable: response.pageable,
    response: listings
  }
}

// export async function getListingById(context) {
//   const id = context.queryKey[1]
//   const graphQLResponse = await fetch(graphQLEndpoint, {
//     method: 'POST',
//     headers: { 'Content-Type': 'application/json' },
//     body: JSON.stringify({ query: listingQuery(id) }),
//   })

//   if (!graphQLResponse.ok) {
//     throw new Error('Network response was not ok')
//   }
//   const data = await graphQLResponse.json()
//   const listing = data?.data?.searchByImportedListingIds[0].importedListing
//   return listing
// }

const proccessor = createFetch(process.env.REACT_APP_API_DOMAIN)
export async function getListingByIdNew(context) {
  const id = context.queryKey[1]
  const response = await proccessor(`/listing-processor/listing/${id}`)
  const imageResponse = await proccessor(`/listing-processor/listings/${id}/images`)
  const videosResponse = await proccessor(`/listing-processor/listings/${id}/videos`)
  response.importedListing.images = imageResponse
  response.importedListing.videos = videosResponse
  return response
}

export async function addListingToCampaign(campaignId, listing) {
  const response = await fetchApi(`/ad-campaign-listing`, {
    method: 'POST',
    body: JSON.stringify(listing)
  })

  return response
}

export async function removeListing(listingID) {
  const response = await fetchApi(`/ad-campaign-listing/${listingID}`, {
    method: 'DELETE'
  })
  return response
}

export async function updateListing(listing) {
  const response = await fetchApi(`/listing-processor?importedListingId=${listing.imported_listing_id}&listingId=${listing.listing_id}`, {
    method: 'PUT',
    body: JSON.stringify(listing)
  })
  return response
}

export async function updateCampaignListing(listing) {
  const response = await fetchApi(`/ad-campaign-listing/${listing.adCampaignListingId}`, {
    method: 'PUT',
    body: JSON.stringify(listing)
  })
  return response.response?.adCampaign
}

export async function getBudgets(context) {
  const id = context.queryKey[1]
  const response = await fetchApi(`/configured-budget/account/${id}`)
  return response.response
}

export async function getContacts(context) {
  const id = context.queryKey[1]
  const response = await fetchApi(`/contacts/account-id/${id}`)
  return response.response
}

export async function createContact(contact) {
  const response = await fetchApi(`/contacts`, {
    method: 'POST',
    body: JSON.stringify(contact)
  })
  return response.response
}

export async function updateContact(contact) {
  const response = await fetchApi(`/contacts/${contact.contactId}`, {
    method: 'PUT',
    body: JSON.stringify(contact)
  })
  return response.response
}

export async function createBudget(budget) {
  const response = await fetchApi(`/configured-budget`, {
    method: 'POST',
    body: JSON.stringify(budget)
  })
  return response.response
}

export async function updateBudget(budget) {
  const budgetId = budget.configuredBudgetId
  const response = await fetchApi(`/configured-budget/${budgetId}`, {
    method: 'PUT',
    body: JSON.stringify(budget)
  })
  return response.response
}

export async function getAccountById(context) {
  const id = context.queryKey[1]
  const response = await fetchApi(`/account/${id}`)
  return response.response
}

const apiFetch = createFetch(`${process.env.REACT_APP_API_DOMAIN}/api/rest`)
export async function getAccounts(context) {
  const page = context.queryKey[1]
  const searchName = context.queryKey[2]
  const size = 1000
  const response = await apiFetch(`/account/search`, {
    method: 'POST',
    body: JSON.stringify({
      display_name: `%${searchName}%`,
      account_source: 'Citygrid',
      limit: size,
      offset: 0
    })
  })

  return snakeToCamel(response.accounts)
}

export async function createAccount(account) {
  const response = await fetchApi(`/account`, {
    method: 'POST',
    body: JSON.stringify(account)
  })
  return response.response
}

export async function updateAccount(account) {
  const accountId = account.accountId
  const response = await fetchApi(`/account/${accountId}`, {
    method: 'PUT',
    body: JSON.stringify(account)
  })
  return response.response
}

const internalFetch = createFetch(`https://api-acct-mgmt-dev.elocal.com${ACT_BASE_PATH}`)
export async function getAccountTypes(context) {
  const response = await fetchApi(`/lookup/lookup-type/ACCOUNT_TYPE`)
  return response.response
}

const baseFetch = createFetch(`${process.env.REACT_APP_API_DOMAIN}/api`)
export async function getBillingStatues(context) {
  const response = await baseFetch(`/rest/billing-statuses`)
  return snakeToCamel(response.billingStatuses)
}

const typeAheadFetch = createFetch(`${process.env.REACT_APP_API_DOMAIN}/type-ahead`)
export async function fetchGeoOptions(prompt, limit) {
  const response = await typeAheadFetch(`/geography/autocomplete?prompt=${prompt}&limit=${limit}`)
  return response.geographies
}

export async function fetchCategoryOptions(prompt, limit) {
  const response = await typeAheadFetch(`/category/autocomplete?prompt=${prompt}&limit=${limit}`)
  return response.categories
}
