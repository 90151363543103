import React from 'react';
import { useTimeout } from '../hooks/useTimeout';
import { ReactComponent as CloseIcon } from 'images/close-icon.svg';

export const Toast = (props) => {
  useTimeout(props.close, 1000000);

  return (
    <div className={`toast ${props.type ? props.type : 'info'}`}>
      <div className="toast-text">{props.children}</div>
      <button onClick={props.close} className={`toast-close-btn ${props.type ? props.type : 'info'}`}>
        <CloseIcon width='14px' height='14px' />
      </button>
    </div>
  );
};
