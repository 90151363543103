import React from 'react'

export const SiteContext = React.createContext()

export const SiteContextProvider = props => {
  const [ state, setState ] = React.useState({
    isLoadingSiteData: true,
  })

  const metadataSrc = window.__path ? `/${window.__path}/metadata.json` : '/metadata.json'

  React.useEffect( () => {
    const updateContext = (metadata, geoInfo) => {
      // Finally, update the context object.
      setState( prevState => ({
        ...prevState,
        ...metadata,
      }))
      setTimeout( () => {
        setState( prevState => ({
          ...prevState,
          isLoadingSiteData: false,
        }))
      }, 500)
    }

    fetch(metadataSrc)
      .then(response => response.json())
      .then(metadata => {
        updateContext(metadata)
      })
      .catch( err => {
        console.log('metadata fetch failed', err)
      })
  }, [state.location])

  return (
    <SiteContext.Provider value={[ state, setState ]}>
      {props.children}
    </SiteContext.Provider>
  )
}
