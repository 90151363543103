import { format } from 'date-fns'

const blankEvents = {
  count: 0,
  avgPrice: 0,
  totalPrice: 0,
  totalCost: 0
}
export function addSupplyEvents(objects) {
  const objCopy = JSON.parse(JSON.stringify(objects))
  return objCopy.map((obj) => {
    const stats = mergeSupplyEventCopies(obj?.supplyEventDetails?.Click)
    const objTypes = ['lowquality', 'surplus', 'billable']
    const completeSupplyEvents = {}
    // console.log({ stats })
    objTypes.forEach((t) => {
      completeSupplyEvents[t] = stats[t]
    })
    const totalClicks = Object.keys(stats).reduce((acc, x) => {
      acc += completeSupplyEvents[x].count
      return acc
    }, 0)
    const billableClicks = completeSupplyEvents.billable.count
    const billableCost = completeSupplyEvents.billable.totalPrice
    return { ...obj, totalClicks, billableClicks, billableCost }
  })
}

// This is used to tell react-query which key to invalidate after a mutation so the new data will fetch
export function queryKeyFactory(queryKey) {
  const factoryKeys = {
    all: [queryKey], // invalidates everything that has that query key no matter if its a list or single record
    lists: () => [...factoryKeys.all, 'list'], // invalidates all lists no matter what the filters are
    list: (filters) => [...factoryKeys.lists(), { ...filters }], // This invalidates all queries that use these exact filters
    details: () => [...factoryKeys.all, 'detail'], // This invalidates all queires that used an id to fetch a single record
    detail: (id) => [...factoryKeys.details(), id] // This invalidates only the record with the matching id
  }
  return factoryKeys
}

const objTypes = ['lowquality', 'surplus', 'billable']
export function formatSuppleEventStats(stats) {
  const statsCopy = JSON.parse(JSON.stringify(stats))
  const supplyEvents = Object.keys(statsCopy).reduce(
    (finalAcc, date) => {
      const statsOnDate = {
        Click: mergeSupplyEventCopies(statsCopy[date].Click)
      }
      const datum = objTypes.reduce(
        (acc, type) => {
          statsOnDate.Click[type] = statsOnDate.Click[type]
          const connData = statsOnDate.Click[type]
          if (type === 'billable') {
            acc.billableConnections = connData.count
            acc.cost = connData.totalPrice
            finalAcc.total.billableConnections += connData.count
            finalAcc.total.cost += connData.totalPrice
          } else {
            acc.nonBillableConnections += connData.count
            finalAcc.total.nonBillableConnections += connData.count
          }
          acc.totalConnections += connData.count
          finalAcc.total.totalConnections += connData.count
          return acc
        },
        {
          date: new Date(date.split('Z')[0]),
          formattedDate: format(new Date(date.split('Z')[0]), 'LLL dd'),
          billableConnections: 0,
          totalConnections: 0,
          nonBillableConnections: 0,
          cost: 0
        }
      )
      finalAcc.arr.push(datum)

      return finalAcc
    },
    {
      total: { billableConnections: 0, totalConnections: 0, cost: 0 },
      arr: []
    }
  )
  supplyEvents.arr = supplyEvents.arr.sort((a, b) => b.date - a.date)
  return supplyEvents
}

/**
 * Everything below here is because supplyevent aggregations are being returned from the api in a weird way
 * and should be cleaned up or all together removed once fixed
 *
 * Supply Events are being returned like
 * {
 *   Surplus : {count: 10, totalPrice: 20},
 *   SURPLUS : {count: 10, totalPrice: 20},
 *   BILLABLE : {count: 10, totalPrice: 20},
 *   Billable : {count: 10, totalPrice: 20}
 * }
 *
 * You need to add up the prices from both keys to get the right value.
 * So the true billableCount is Billable.count + BILLABLE.count
 *
 * But keys may be missing so you might have a object that has BILLABLE but not Billable.
 *
 * So I loop through all the keys I need and add in the ones that are missing so if
 * BILLABLE isn't present i set it equal to BILLABLE: {count: 0, totalPrice: 0, totalCost: 0}
 *
 * All of the fields SHOULD be on there if the key is present but I decided to guard against it just
 * in case for some reason totatlPrice or count isn't present
 *
 * When they are merged i set the key to lowercase
 * Example
 *
 * Input
 * {
 *   Surplus : {count: 10, totalPrice: 20},
 *   BILLABLE : {count: 10, totalPrice: 20},
 *   Billable : {count: 10, totalPrice: 20}
 * }
 *
 * Should turn into
 * {
 *   surplus: { count: 10, totalPrice: 20 }
 *   billable: { count: 20: totaltPrice: 40 }
 *   lowquality: { count: 0, toatlPrice: 0 }
 * }
 */

const dataGroupKeys = [
  ['SURPLUS', 'Surplus'],
  ['LOWQUALITY', 'LowQuality'],
  ['BILLABLE', 'Billable']
]
const dataFields = ['count', 'totalPrice', 'totalCost']

// This is where i check if DataGroup.count  is on the object and actually a number
// Probably not needed but just in case
const checkIfNumber = (number) => {
  if (isNaN(number) || number === 'undefined' || number === null) {
    return 0
  }
  return number
}

const emptyEvent = {
  count: 0,
  avgPrice: 0,
  totalPrice: 0,
  totalCost: 0
}

/**
 * This is where I grab the values from the Key if they are present
 * if they arnen't I fill them with an emptyEvent where everything is
 * equal to 0
 */
const getOrFillDatum = (keys, data) => {
  return keys.map((x) => {
    return data[x] || emptyEvent
  })
}

/**
 * This function has 2 uses
 * 1. If the Click event stats are null I fill them in with empty events and return the object
 *  Ex {surplus: {count: 0, totalPrice: 0}, billable: {count: 0, totalPrice: 0}, ...}
 * 2. It creates empty objects so when I reduce the values down to merge them I don't
 *    have to check for and set an empty object
 *  EX:  {surplus: {}, billable: {}, ...}
 */
const getEmptyDataGroup = ({ fill = false } = {}) =>
  dataGroupKeys.reduce((acc, dataGroup) => {
    acc[dataGroup[0].toLowerCase()] = fill ? emptyEvent : {}
    return acc
  }, {})

function mergeSupplyEventCopies(datum) {
  // If datum is null fill it with empty values and return
  if (!datum) {
    const emptyDatum = getEmptyDataGroup({ fill: true })
    return emptyDatum
  }

  // Loop over the dataGroups then check if they are null
  // If it is null fill it with empty data
  // Then loop over the fields in the two groups that should be merged and add them together.
  return dataGroupKeys.reduce((acc, keys) => {
    const filled = getOrFillDatum(keys, datum)
    dataFields.forEach((field) => {
      const dataGroupKey = keys[0].toLowerCase()
      acc[dataGroupKey][field] = filled.reduce((acc, obj) => checkIfNumber(obj[field]) + acc, 0)
    })
    return acc
  }, getEmptyDataGroup())
}
