import { getSession } from 'context/getSession'

function createFetch(DOMAIN) {
  return async function fetchApi(path, options = {}, t) {
    const { idToken, accessToken } = await getSession()
    let url = `${DOMAIN}${path}`
    const { proxy, ...otherOptions } = options
    // if (options.proxy) {
    // console.log(url)
    // const forwardUrl = encodeURIComponent(url)
    // console.log({ forwardUrl })
    // url = `https://ab7lefvjj3.execute-api.us-east-1.amazonaws.com/Prod?forwardUrl=${forwardUrl}=&key=xyz`
    // url = `http://127.0.0.1:3000?forwardUrl=${forwardUrl}&key=xyz`
    // console.log(url)
    // }
    otherOptions.headers = {
      // 'Content-Type': t ? 'multipart/form-data' : 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${accessToken.jwtToken}`,
      'Id-token': idToken.jwtToken
    }
    if (!t) {
      otherOptions.headers['Content-Type'] = 'application/json'
    }
    try {
      const response = await fetch(url, otherOptions)
      if (response.status === 204) {
        return 'ok'
      }
      const data = await response.json()
      if (!response.ok) {
        let error = 'Network response was not ok'
        if (data.error_message) {
          error = data.error_message
        }
        if (data.errors) {
          error = data.errors
        }
        throw new Error(error)
      }
      return data
    } catch (error) {
      throw new Error(error)
    }
  }
}
export default createFetch
