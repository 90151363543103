import React from 'react'

import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { SiteContextProvider } from './context/site-context'
import { AuthProvider } from 'context/auth-context'
import { AccountProvider } from 'context/account-context'
import { ToastProvider } from 'components/atoms/Toast/ToastProvider'

import App from './App'
import ScrollToTop from 'components/atoms/ScrollToTop'

import './index.scss'

const queryClient = new QueryClient()
const history = createBrowserHistory()
const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1]

if (path) {
  history.replace(path)
}

const root = createRoot(document.getElementById('root'))
root.render(
  <SiteContextProvider>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ScrollToTop>
          <ToastProvider>
            <AuthProvider>
              <AccountProvider>
                <App />
              </AccountProvider>
            </AuthProvider>
          </ToastProvider>
        </ScrollToTop>
      </BrowserRouter>
    </QueryClientProvider>
  </SiteContextProvider>
)
