import React from 'react'

import { SiteContext } from 'context/site-context'

import Logo from 'components/atoms/Logo'

import './index.scss'

const Footer = () => {
  const [ site ] = React.useContext(SiteContext)

  return (
    <footer>
      <div className="copyright">
        Copyright &copy; {new Date().getFullYear()} eLocal USA LLC. {site.domain} is a registered trademark of eLocal. All Rights Reserved.
      </div>
    </footer>
  )
}

export default Footer
