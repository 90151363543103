import React from 'react'

import { SiteContext } from 'context/site-context'

import { useHistory } from 'react-router-dom'

import './index.scss'

const Logo = props => {
  const [ site, setSite ] = React.useContext(SiteContext)

  const history = useHistory()

  const handleClick = e => {
    e.preventDefault()
    e.stopPropagation()

    setSite({
      ...site,
      showHeader: false,
    })

    history.push('/campaigns')
  }

  return <a href="/campaigns"
    className="main-logo"
    onClick={ e => handleClick(e) }>
      <img src={site.logo} alt={site.domain} /><span className="divider">&nbsp;</span><span>Citygrid</span>
    </a>
}

export default Logo
