import _ from 'lodash'

function snakeToCamel(arr) {
  let returnArray = []
  arr.forEach((element) => {
    let newObj = _.mapKeys(element, (value, key) => _.camelCase(key))
    Object.keys(newObj).forEach((key, index) => {
      if (newObj[key] === null) {
        // don't do anything
      } else if (typeof newObj[key] === 'object') {
        newObj[key] = _.mapKeys(newObj[key], (value, key) => _.camelCase(key))
      }
    })

    returnArray.push(newObj)
  })
  return returnArray
}

export default snakeToCamel
