import { useHistory } from 'react-router-dom'
import './index.scss'

function ErrorFallback({ error, resetErrorBoundary }) {
  const history = useHistory()
  const onReset = () => {
    history.push('/campaigns')
    resetErrorBoundary()
  }
  return (
    <main className="main-container">
      <div role="alert" className="error-fallback">
        <h1>Something went wrong:</h1>
        <pre>{error.message}</pre>
        <button className="button-alt submit" onClick={onReset}>
          Try again
        </button>
      </div>
    </main>
  )
}

export default ErrorFallback
